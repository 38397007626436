import { ConnectorData } from "@wagmi/connectors";
import { Address, Chain, Connector, ConnectorNotFoundError } from "wagmi";
import { LedgerProvider } from "./LedgerProvider";
import { LedgerSigner } from "./LedgerSigner";
import { LedgerConnectorOptions } from "./types";
import { checkError, isHIDSupported } from "./helpers";

interface LedgerConfig {
  chains?: Chain[];
  options?: LedgerConnectorOptions;
}

export class LedgerConnector extends Connector<
  LedgerProvider,
  LedgerConnectorOptions,
  LedgerSigner
> {
  readonly id = "ledger";
  readonly name = "Ledger";
  readonly ready = true;
  readonly chain: Chain;

  protected provider?: LedgerProvider;

  // TODO: подумать на chains, как нам работать с ними в рамках Ledger
  constructor({ chains, options }: LedgerConfig) {
    super({ chains, options });

    this.chain = chains[0];
  }

  public isSupported(): boolean {
    return isHIDSupported();
  }

  async getProvider(): Promise<LedgerProvider> {
    if (!this.provider) {
      this.provider = new LedgerProvider({
        chain: this.chain,
        options: this.options,
      });
    }
    return this.provider;
  }

  async connect(config?: {
    chainId?: number;
  }): Promise<Required<ConnectorData<LedgerProvider>>> {
    try {
      const provider = await this.getProvider();
      if (!provider) throw new ConnectorNotFoundError();

      if (provider.on) {
        provider.on("accountsChanged", this.onAccountsChanged);
        provider.on("chainChanged", this.onChainChanged);
        provider.on("disconnect", this.onDisconnect);
      }

      const { hid } = window.navigator;

      // TODO: подумать чо с этим делать
      const onDisconnect = (event: HIDConnectionEvent) => {
        if (this.provider.device === event.device) {
          hid.removeEventListener("disconnect", onDisconnect);
          this.emit("disconnect");
        }
      };

      hid.addEventListener("disconnect", onDisconnect);

      const account = await this.provider.activate();
      const id = await this.getChainId();

      return {
        provider: this.provider,
        account,
        chain: { id, unsupported: false },
      };
    } catch (error) {
      return checkError(error);
    }
  }
  async disconnect(): Promise<void> {
    const provider = await this.getProvider();

    await provider.deactivate();

    if (!provider?.removeListener) return;

    provider.removeListener("accountsChanged", this.onAccountsChanged);
    provider.removeListener("chainChanged", this.onChainChanged);
    provider.removeListener("disconnect", this.onDisconnect);

    this.emit("disconnect");
  }
  async getAccount(): Promise<Address> {
    return await this.provider.getAddress();
  }

  async getChainId() {
    return this.chain.id;
  }

  async getSigner(config?: { chainId?: number }): Promise<LedgerSigner> {
    return this.provider.getSigner();
  }

  async isAuthorized(): Promise<boolean> {
    try {
      const provider = await this.getProvider();
      if (!provider) throw new ConnectorNotFoundError();

      const isAccountConnected = window.localStorage.getItem("wagmi.connected");
      if (!isAccountConnected) return false;

      const account = await provider.activate();
      return !!account;
    } catch {
      return false;
    }
  }

  protected onAccountsChanged(accounts: Address[]): void {
    console.log("onAccountsChanged called", accounts);
  }
  protected onChainChanged(chain: string | number): void {
    console.log("onChainChanged called", chain);
  }
  protected onDisconnect(event: Error): void {
    console.log("onDisconnect called", event);

    this.emit("disconnect");
  }
}
