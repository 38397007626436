import { createElement } from "react";

import "./Skeleton.css";

export const Skeleton = ({ multilineConfig = [], width }) => {
  const skeletonConfig = !multilineConfig.length ? [width] : multilineConfig;
  return skeletonConfig.map((lineWidth, idx) =>
    createElement("div", {
      key: idx,
      className: "skeleton-line",
      style: { width: lineWidth },
    })
  );
};
