export const fetcher = async (url, options) => {
  try {
    let targetOptions = options ? { ...options } : {};

    if ("body" in targetOptions) {
      targetOptions.body = JSON.stringify(targetOptions.body);
    }

    const response = await fetch(url, targetOptions);
    const jsonResponse = await response.json();

    if (!response.ok) {
      return [jsonResponse, null];
    }

    return [null, jsonResponse];
  } catch (error) {
    return [error, null];
  }
};
