import * as React from "react";
const SvgQuestionMark = (props) => (
  <svg
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.887 6.623v-.14c.002-.484.045-.869.128-1.156.086-.288.21-.52.373-.696.163-.177.36-.337.588-.48.172-.111.325-.226.46-.345.136-.119.243-.25.323-.393.08-.147.12-.31.12-.49a.915.915 0 0 0-.136-.5.91.91 0 0 0-.369-.332 1.098 1.098 0 0 0-.505-.116c-.18 0-.35.04-.51.12a.973.973 0 0 0-.394.348 1.067 1.067 0 0 0-.165.568H.11c.013-.553.146-1.008.397-1.367.251-.362.584-.632.999-.808.414-.18.871-.27 1.37-.27.55 0 1.037.092 1.46.274.422.18.753.44.994.783.24.342.36.755.36 1.239 0 .323-.054.61-.162.861a2.1 2.1 0 0 1-.443.663c-.19.19-.416.363-.675.518-.218.13-.398.265-.539.406a1.38 1.38 0 0 0-.31.489c-.067.185-.101.413-.104.684v.14h-1.57Zm.82 2.984a.973.973 0 0 1-.708-.29.953.953 0 0 1-.286-.704.935.935 0 0 1 .286-.696.973.973 0 0 1 .708-.29c.262 0 .493.097.692.29a.95.95 0 0 1 .157 1.197c-.09.15-.21.27-.36.36a.942.942 0 0 1-.489.133Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgQuestionMark;
