import * as React from "react";
const SvgDownload = (props) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3.5h-2a.5.5 0 0 0-.5.5v4.5H8.04a.5.5 0 0 0-.39.812l2.96 3.7c.2.25.58.25.78 0l2.96-3.7a.5.5 0 0 0-.39-.812H12.5V4a.5.5 0 0 0-.5-.5Z"
      fill="#1347FF"
    />
    <path
      d="M4 12v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4"
      stroke="#1347FF"
      strokeWidth={2}
    />
  </svg>
);
export default SvgDownload;
