import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App.tsx";

import "./properties.css";
import "./index.css";

import "./focus-visible";

if (
  process.env.NODE_ENV === "development" &&
  process.env.ENABLE_MSW === "true"
) {
  const { worker } = require("./mocks/browser");
  worker.start({
    onUnhandledRequest(req) {
      if (req.url.pathname.includes("/api/")) {
        console.warn(`[MSW] Unhandled api request: ${req.url}`);
      }
    },
  });
}

createRoot(document.getElementById("root")).render(<App />);
