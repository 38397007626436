import React from "react";
import { Dropdown } from "../Dropdown/Dropdown";

import { P2PLogo } from "../Icons";

import "./Header.css";

export const Header = () => {
  return (
    <header className="header ma">
      <a href="https://p2p.org/" target="_blank">
        <P2PLogo height="20px" />
      </a>

      <Dropdown />
    </header>
  );
};
