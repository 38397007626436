import React from "react";
import { cn } from "../../helpers/classnames";

export const Link = ({ href = "", children, className, ...props }) => {
  const isExternal = href.includes("://");
  const externalProps = isExternal
    ? {
        target: "_blank",
        rel: "nofollow noopener",
      }
    : {};

  return (
    <a
      {...props}
      href={href}
      {...externalProps}
      className={cn(["app-link", className])}
    >
      {children}
    </a>
  );
};
