import React from "react";

import { SpinnerLoader } from "../Icons";

import "./Loader.css";

export const Loader = ({ width, height, color }) => {
  return (
    <div className="icon-wrapper" style={{ width, height, color }}>
      <SpinnerLoader />
    </div>
  );
};
