import React from "react";

import { Block } from "../Block/Block";
import { P2PLogo } from "../Icons";

import "./MobileNoop.css";

export const MobileNoop = () => {
  return (
    <div className="container pt16 pb16">
      <P2PLogo height={20} />
      <Block tac className="noop-view">
        <h1 className="h2 pb16">
          Please open the deposit page on your desktop device
        </h1>
        <p className="body-m descr">
          We put security first and disabled depositing from mobile since It’s
          easier to compromise than a laptop
        </p>
      </Block>
    </div>
  );
};
