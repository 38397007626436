import React from "react";
import { cn } from "../../helpers/classnames";
import { Skeleton } from "../Skeleton/Skeleton";
import { QuestionMark } from "../Icons";

import "./DetailsList.css";

const SKELETON_WIDTHS = ["26.3%", "17%", "100%", "33.1%"];

const TooltipButton = ({ children = "" }) => (
  <div className="tooltip-container">
    <button
      className="base-btn tooltip-container__button"
      type="button"
      aria-label="more info"
      data-toggletip-content={children.toString()}
    >
      <QuestionMark width="6px" height="9px" />
    </button>
    <span className="tooltip-container__content description" role="status">
      {children}
    </span>
  </div>
);

export const DetailsList = ({
  data = [],
  className,
  isLoading,
  skeletonData = [],
}) => {
  const skeletonProps = data.map((_, idx) => ({
    width: SKELETON_WIDTHS[idx] || SKELETON_WIDTHS[0],
  }));
  return (
    <ul className={cn(["details-list", className])}>
      {data.map((item, idx) => (
        <li key={item.name + idx} className="details-item">
          <div className="item-key description">
            {item.name}{" "}
            {item.tooltipContent ? (
              <TooltipButton>{item.tooltipContent}</TooltipButton>
            ) : null}
          </div>
          <div
            className={cn([
              "item-value",
              "description-bold",
              { pb24: idx !== data.length - 1 },
            ])}
          >
            {isLoading ? (
              <Skeleton
                {...(skeletonData[idx]
                  ? skeletonData[idx]
                  : skeletonProps[idx])}
              />
            ) : (
              item.value
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
