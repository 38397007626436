import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import { cn } from "../../helpers/classnames";

import { Chevron, Calendly, Email, Telegram } from "../Icons";

import "./Dropdown.css";
import { DepositContext } from "../../context/deposit";

const DROPDOWN_DATA = [
  {
    label: "Chat in Telegram",
    value: "telegram",
    icon: <Telegram aria-hidden="true" width={24} height={24} />,
  },
  {
    label: "Book a call",
    value: "call",
    icon: <Calendly aria-hidden="true" width={24} height={24} />,
  },
  {
    label: "Email",
    value: "email",
    icon: <Email aria-hidden="true" width={24} height={24} />,
  },
];

export const Dropdown = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const menuButtonRef = useRef(null);

  const { depositInfo } = useContext(DepositContext);

  const handleDropdown = useMemo(
    () => ({
      toggle: () => setIsExpanded(!isExpanded),
      open: () => setIsExpanded(true),
      close: () => {
        if (menuButtonRef.current) {
          menuButtonRef.current.focus();
        }
        setIsExpanded(false);
      },
    }),
    [isExpanded]
  );

  const handleChoose = useCallback(
    ({ currentTarget }) => {
      const optionValue = currentTarget.dataset.value;
      if (optionValue === "telegram") {
        window.open(
          depositInfo?.supportChannelLink || "https://t.me/P2P_staking",
          "_blank"
        );
      }
      if (optionValue === "call") {
        window.open(
          "https://calendly.com/d/g58-5fv-rtv/p2p-validator-bd-meeting",
          "_blank"
        );
      }
      if (optionValue === "email") {
        window.open("mailto:letsgo@p2p.org", "_blank");
      }
      handleDropdown.close();
    },
    [handleDropdown]
  );

  return (
    <div className="dropdown">
      <button
        className="base-btn dropdown__selected dropdown-selected"
        aria-haspopup="true"
        aria-expanded={isExpanded}
        onClick={handleDropdown.toggle}
        ref={menuButtonRef}
      >
        <span className="body-m-semibold">Support</span>
        <span className="dropdown-selected__icon">
          <Chevron width={12} height={12} aria-hidden="true" />
        </span>
      </button>

      <div
        className={cn([
          "dropdown__content",
          {
            "dropdown__content--visible": isExpanded,
          },
        ])}
        role="menu"
      >
        {DROPDOWN_DATA.map((option) => (
          <button
            key={option.value}
            data-value={option.value}
            className="base-btn dropdown__option dropdown-option"
            onClick={handleChoose}
          >
            <span className="dropdown-option__icon">{option.icon}</span>
            <span className="body-s dropdown-option__label">
              {option.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};
