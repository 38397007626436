import React, { createElement, FC, PropsWithChildren, ReactNode } from "react";

import { Block } from "../Block/Block";
import { Link } from "../Link/Link";
import { cn } from "../../helpers/classnames";

import * as styles from "./Text.module.css";

interface TextButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: ReactNode;
  href?: string;
}

export const TextButton: FC<PropsWithChildren<TextButtonProps>> = ({
  children,
  icon,
  href,
  ...restProps
}) => {
  const RootElement = (props) => createElement(href ? Link : "button", props);
  return (
    <RootElement
      className={cn(["base-btn", styles.BtnText])}
      href={href}
      {...restProps}
    >
      {icon && (
        <Block as="span" className={styles.BtnText__icon}>
          {icon}
        </Block>
      )}
      <span className="description">{children}</span>
    </RootElement>
  );
};
