import React, { createRef, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { BodyScrollService } from "../../services/body-scroll";

import { ModalCloseIcon } from "../Icons";

import "./Modal.css";

export const Portal = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted ? createPortal(children, document.body) : null;
};

export const Modal = ({ children, closeOnOutsideClick, onClose, isOpen }) => {
  const modalBodyRef = createRef(null);

  /** Prevent bubbling event to filter outside clicks */
  const handleCardClick = (e) => e.stopPropagation();

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  const handleKeyDown = useCallback(
    ({ code }) => {
      if (code === "Escape" && closeOnOutsideClick) handleClose();
    },
    [handleClose, closeOnOutsideClick]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      BodyScrollService.lockScroll();
      document.querySelector("#root")?.setAttribute("inert", "");
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      BodyScrollService.unlockScroll();
      document.querySelector("#root")?.removeAttribute("inert");
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      BodyScrollService.unlockScroll();
      document.querySelector("#root")?.removeAttribute("inert");
    };
  }, [handleKeyDown, isOpen]);

  if (!isOpen) return null;

  return (
    <Portal>
      <div
        className="modal-overlay"
        onClick={closeOnOutsideClick ? handleClose : undefined}
        role="dialog"
        aria-labelledby="modal-title"
      >
        <div className="modal-content" ref={modalBodyRef}>
          {(closeOnOutsideClick || onClose) && (
            <button
              className="base-btn modal-content__close-btn"
              onClick={handleClose}
              aria-label="Close modal"
            >
              <ModalCloseIcon aria-hidden="true" />
            </button>
          )}

          <div className="modal-content__children" onClick={handleCardClick}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
