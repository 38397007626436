import * as React from "react";
const SvgMetamask = (props) => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.81 3.343 16.442 10.3l1.733-4.105 7.637-2.853Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m4.18 3.344 9.294 7.024-1.648-4.171L4.18 3.344ZM22.437 19.475l-2.496 3.823 5.34 1.469 1.534-5.208-4.378-.084ZM3.191 19.56l1.526 5.207 5.339-1.47-2.495-3.822-4.37.084Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.755 13.015-1.487 2.25 5.3.236-.187-5.697-3.626 3.21ZM20.236 13.015l-3.672-3.277-.123 5.763 5.292-.235-1.497-2.25ZM10.057 23.296l3.182-1.553-2.75-2.147-.432 3.7ZM16.75 21.743l3.192 1.553-.442-3.7-2.75 2.147Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m19.946 23.296-3.192-1.554.254 2.081-.028.876 2.966-1.403ZM10.059 23.296l2.966 1.403-.02-.876.236-2.08-3.182 1.553Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.07 18.22-2.656-.781 1.874-.857.781 1.638ZM16.922 18.22l.781-1.638 1.884.857-2.665.781Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.058 23.296.452-3.823-2.947.084 2.495 3.739ZM19.494 19.473l.452 3.823 2.495-3.739-2.947-.084ZM21.733 15.264l-5.292.235.49 2.721.782-1.638 1.883.857 2.137-2.175ZM10.415 17.439l1.883-.857.772 1.638.499-2.72-5.301-.236 2.146 2.175Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.27 15.264 2.222 4.331-.076-2.156-2.146-2.175ZM19.596 17.439l-.094 2.156 2.232-4.331-2.138 2.175ZM13.571 15.499l-.499 2.721.622 3.211.141-4.228-.264-1.704ZM16.444 15.499l-.255 1.695.113 4.237.631-3.21-.49-2.722Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.932 18.221-.631 3.21.452.312 2.75-2.147.093-2.157-2.664.782ZM10.414 17.44l.075 2.156 2.75 2.147.452-.311-.622-3.211-2.655-.782Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.98 24.7.028-.876-.236-.207h-3.55l-.216.207.019.876-2.966-1.403 1.035.847 2.1 1.46h3.607l2.109-1.46 1.036-.847L16.98 24.7Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.752 21.742-.452-.31H13.69l-.452.31-.235 2.081.216-.207h3.55l.236.207-.254-2.08Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m26.208 10.754.8-3.842-1.196-3.568-9.058 6.723 3.484 2.947 4.925 1.44 1.092-1.27-.471-.34.753-.687-.584-.452.754-.574-.5-.377ZM2.996 6.912l.8 3.842-.508.377.753.574-.574.452.753.688-.47.339 1.082 1.27 4.925-1.44 3.484-2.947-9.058-6.723-1.187 3.568Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m25.161 14.454-4.925-1.44 1.498 2.25-2.232 4.332 2.938-.038h4.378l-1.657-5.104ZM9.756 13.014l-4.924 1.44-1.639 5.104h4.37l2.928.038-2.222-4.332 1.487-2.25ZM16.442 15.5l.31-5.433 1.432-3.87h-6.356l1.413 3.87.33 5.434.112 1.713.01 4.219H16.3l.019-4.219.122-1.713Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.121}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMetamask;
