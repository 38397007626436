import * as React from "react";
const SvgTelegram = (props) => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
      fill="url(#telegram_svg__a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 16.584c-.324 0-.269-.123-.38-.431l-.953-3.135 7.333-4.351"
      fill="#C8DAEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.666 16.584c.25 0 .36-.115.5-.25l1.333-1.297-1.663-1.003"
      fill="#A9C9DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.836 14.035 4.03 2.977c.46.254.792.123.907-.427l1.64-7.73c.168-.673-.257-.979-.697-.779L7.084 11.79c-.658.264-.654.63-.12.794l2.472.772 5.723-3.61c.27-.165.518-.076.314.104"
      fill="url(#telegram_svg__b)"
    />
    <defs>
      <linearGradient
        id="telegram_svg__a"
        x1={10.001}
        y1={2.834}
        x2={5.001}
        y2={14.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37AEE2" />
        <stop offset={1} stopColor="#1E96C8" />
      </linearGradient>
      <linearGradient
        id="telegram_svg__b"
        x1={11.248}
        y1={12.615}
        x2={12.316}
        y2={16.071}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF7FC" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTelegram;
