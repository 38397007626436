import * as React from "react";
const SvgCalendly = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.807 15.12c-.677.6-1.521 1.348-3.053 1.348h-.916c-1.109 0-2.117-.403-2.839-1.134-.705-.714-1.093-1.692-1.093-2.753v-1.254c0-1.061.388-2.038 1.093-2.752.722-.731 1.73-1.134 2.839-1.134h.916c1.533 0 2.376.747 3.053 1.348.702.62 1.308 1.16 2.924 1.16.247 0 .493-.02.736-.058l-.005-.015a5.553 5.553 0 0 0-.34-.698l-1.08-1.873a5.55 5.55 0 0 0-4.806-2.776h-2.16A5.545 5.545 0 0 0 6.27 7.305L5.19 9.178a5.557 5.557 0 0 0 0 5.553l1.08 1.872a5.551 5.551 0 0 0 4.806 2.776h2.16a5.545 5.545 0 0 0 4.806-2.776l1.08-1.87c.13-.224.243-.458.34-.698l.005-.014a4.57 4.57 0 0 0-.736-.062c-1.616 0-2.222.539-2.924 1.161"
      fill="#006BFF"
    />
    <path
      d="M12.754 8.578h-.916c-1.688 0-2.796 1.206-2.796 2.75v1.254c0 1.543 1.108 2.75 2.796 2.75h.916c2.458 0 2.265-2.51 5.977-2.51.352 0 .703.033 1.049.097a5.552 5.552 0 0 0 0-1.929 5.718 5.718 0 0 1-1.049.097c-3.713 0-3.519-2.509-5.977-2.509Z"
      fill="#006BFF"
    />
    <path
      d="M21.913 13.836a5.174 5.174 0 0 0-2.133-.918v.02c-.066.369-.17.731-.309 1.08a4.057 4.057 0 0 1 1.763.726c0 .005-.003.012-.005.017a9.5 9.5 0 0 1-16.286 3.397A9.516 9.516 0 0 1 10.728 2.55a9.5 9.5 0 0 1 10.5 6.599c0 .005.003.011.005.016a4.05 4.05 0 0 1-1.763.726c.14.35.243.712.31 1.082v.018c.77-.14 1.5-.454 2.132-.917.607-.45.49-.959.398-1.26a10.66 10.66 0 0 0-4.512-5.885A10.647 10.647 0 0 0 4.055 5a10.666 10.666 0 0 0 6.488 17.497c2.522.38 5.097-.158 7.256-1.516a10.66 10.66 0 0 0 4.512-5.885c.092-.301.21-.81-.398-1.26Z"
      fill="#006BFF"
    />
    <path
      d="M19.467 9.89c-.243.041-.49.062-.736.062-1.616 0-2.222-.538-2.924-1.16-.677-.601-1.52-1.349-3.053-1.349h-.916c-1.11 0-2.117.404-2.839 1.134-.705.715-1.093 1.692-1.093 2.753v1.254c0 1.061.388 2.039 1.093 2.753.722.73 1.73 1.133 2.839 1.133h.916c1.533 0 2.376-.747 3.053-1.347.702-.62 1.308-1.161 2.924-1.161.247 0 .493.02.736.059.14-.35.244-.711.31-1.081v-.019a5.717 5.717 0 0 0-1.049-.096c-3.713 0-3.52 2.51-5.977 2.51h-.916c-1.688 0-2.796-1.207-2.796-2.75v-1.258c0-1.543 1.108-2.749 2.796-2.749h.916c2.458 0 2.265 2.508 5.977 2.508.352 0 .703-.032 1.049-.096v-.017a5.597 5.597 0 0 0-.31-1.082Z"
      fill="#0AE8F0"
    />
    <path
      d="M19.467 9.89c-.243.041-.49.062-.736.062-1.616 0-2.222-.538-2.924-1.16-.677-.601-1.52-1.349-3.053-1.349h-.916c-1.11 0-2.117.404-2.839 1.134-.705.715-1.093 1.692-1.093 2.753v1.254c0 1.061.388 2.039 1.093 2.753.722.73 1.73 1.133 2.839 1.133h.916c1.533 0 2.376-.747 3.053-1.347.702-.62 1.308-1.161 2.924-1.161.247 0 .493.02.736.059.14-.35.244-.711.31-1.081v-.019a5.717 5.717 0 0 0-1.049-.096c-3.713 0-3.52 2.51-5.977 2.51h-.916c-1.688 0-2.796-1.207-2.796-2.75v-1.258c0-1.543 1.108-2.749 2.796-2.749h.916c2.458 0 2.265 2.508 5.977 2.508.352 0 .703-.032 1.049-.096v-.017a5.597 5.597 0 0 0-.31-1.082Z"
      fill="#0AE8F0"
    />
  </svg>
);
export default SvgCalendly;
