export const cn = (namesArray = []) =>
  namesArray
    .filter(Boolean)
    .map((name) => {
      if (typeof name === "string") {
        return name;
      }
      if (typeof name === "object") {
        return Object.keys(name)
          .map((key) => Boolean(name[key]) && key)
          .filter(Boolean)
          .join(" ");
      }
      return "";
    })
    .join(" ")
    .replaceAll(`/\s\s/`, "s");
