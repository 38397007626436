import * as React from "react";
const SvgRocket = (props) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.973 26.023a.857.857 0 0 1-.608-1.466l1.208-1.208a.857.857 0 0 1 1.209 1.208l-1.2 1.243a.857.857 0 0 1-.609.223ZM25.056 9.515v2.511a9.37 9.37 0 0 1-2.76 6.669l-2.134 2.134-.506 3.54a2.151 2.151 0 0 1-.608 1.208l-.609.609a1.285 1.285 0 0 1-1.817 0l-5.143-5.143-1.826 1.809a.858.858 0 1 1-1.208-1.217l1.817-1.835-2.726-2.708a1.286 1.286 0 0 1 0-1.817l.609-.618a2.152 2.152 0 0 1 1.208-.6l3.54-.505 2.135-2.135a9.368 9.368 0 0 1 6.702-2.76h2.512a.857.857 0 0 1 .814.858Zm-4.491 3.634a.857.857 0 0 0-1.209 1.208.857.857 0 1 1-1.208 0 .857.857 0 1 0-1.218-1.208 2.571 2.571 0 1 0 3.635 0Z"
      fill="#0B0B0B"
    />
  </svg>
);
export default SvgRocket;
