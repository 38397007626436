import React, { useContext } from "react";

import { BaseCard } from "../BaseCard/BaseCard.jsx";
import { DetailsList } from "../DetailsList/DetailsList.jsx";
import { Link } from "../Link/Link.jsx";
import { Block } from "../Block/Block.jsx";
import { Button } from "../Button/Button.jsx";
import { CheckedBold, Link as LinkIcon, Telegram } from "../Icons";

import "./SentToValidatorStage.css";
import { DepositContext } from "../../context/deposit";
import { ETHERSCAN_URLS } from "../../constants/etherscan";
import { mainnetId } from "../../constants/network-chain-ids";

const CheckedMark = () => (
  <span className="checked-mark">
    <CheckedBold />
  </span>
);

const CounterMark = ({ children }) => (
  <span className="counter-mark description-bold">{children}</span>
);

export const SentToValidatorStage = () => {
  const { depositInfo } = useContext(DepositContext);

  return (
    <BaseCard size="md" className="ma">
      <p className="status body-s-bold pb16">
        <CheckedMark /> Success
      </p>
      <h1 className="h3 pb16">Deposits were sent to your validators</h1>
      <p className="body-s disclaimer-card disclaimer-card--success mb32">
        You have successfully deposited your funds, and the validators are now
        in the queue for activation.{" "}
        <Link
          href={`${ETHERSCAN_URLS[mainnetId]}/tx/${depositInfo?.transaction.hash}`}
          className="body-s-bold link-w-icon"
        >
          View on Etherscan <LinkIcon width={14} />
        </Link>{" "}
        <br />
        We will send the{" "}
        <span className="body-s-bold">
          transaction snapshot to email address
        </span>{" "}
      </p>
      <DetailsList
        className="pb32"
        data={[
          {
            name: "Deposited funds",
            value: <>{depositInfo?.amount} ETH</>,
          },
          {
            name: "Deposited from",
            value: <>{depositInfo?.transaction.from}</>,
          },
          {
            name: "Withdrawal address",
            value: <>{depositInfo?.withdrawalAddress}</>,
          },
        ]}
      />

      <Block className="pb32">
        <h3 className="body-m-semibold pb16">What's next?</h3>
        <ul className="next-steps">
          <li className="pb16 next-steps__item">
            <CheckedMark />
            Deposit was sent, and validators are in the activation queue
          </li>
          <li className="pb16 next-steps__item">
            <CounterMark>2</CounterMark> It takes <b> 16-24 </b> hours until
            validators become active
          </li>
          <li className="pb16 next-steps__item">
            <CounterMark>3</CounterMark>
            You start <b> getting rewards </b> right away after activation
          </li>
        </ul>
      </Block>

      <div className="disclaimer-card">
        <p className="body-s pb24">
          <span className="body-s-bold">You will receive</span> all
          notifications and updates on these stages in the dedicated{" "}
          <span className="body-s-bold">Telegram chat</span>
        </p>
        <Button
          fullWidth
          icon={<Telegram width={24} height={24} />}
          href={depositInfo?.supportChannelLink}
        >
          Go to a Dedicated Telegram Chat
        </Button>
      </div>
    </BaseCard>
  );
};
