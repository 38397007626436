.AccountCard {
  width: 488px;
}

.AccountCard__walletRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 11px;
  color: var(--black50);
}

.AccountCard__userRow {
  display: flex;
  gap: 13px;
  align-items: center;
  padding-bottom: 16px;
}

.WalletAvatar {
  width: 32px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.WalletAvatar:empty {
  background-color: var(--grey);
}

.AccountCard__actionsRow {
  display: flex;
  align-items: center;
  gap: 16px;
}
