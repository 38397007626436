.AccountContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AccountContent__balanceLabel {
  padding-bottom: 4px;
}

.AccountContent__userProfile {
  height: 55px;
  width: 177px;
  border-radius: 100px;
  padding: 9px;
  border: 1px solid var(--blue);
  background-color: var(--ultraLightGrey);
  display: flex;
}

.AccountContent__userProfile:not(:disabled):global(.focus-visible) {
  outline: 8px solid var(--blue20);
  outline-offset: 0;
}

.AccountContent__userProfile:hover {
  background-color: var(--lightGrey);
}

.AccountContent__address {
  margin-left: auto;
}

.AccountContent__avatar {
  margin-left: auto;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-color: grey;
}
