import * as React from "react";
const SvgAlertIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={24}
      cy={24}
      r={22.5}
      stroke="#17192D"
      strokeOpacity={0.4}
      strokeWidth={3}
    />
    <rect
      x={23}
      y={11}
      width={3}
      height={18}
      rx={1.5}
      fill="#17192D"
      fillOpacity={0.4}
    />
    <rect
      x={23}
      y={32}
      width={3}
      height={3}
      rx={1.5}
      fill="#17192D"
      fillOpacity={0.4}
    />
  </svg>
);
export default SvgAlertIcon;
