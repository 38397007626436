import React, { useContext, useEffect } from "react";

import { BaseCard } from "../BaseCard/BaseCard.jsx";
import { DetailsList } from "../DetailsList/DetailsList.jsx";
import { Link } from "../Link/Link.jsx";
import { Link as LinkIcon } from "../Icons";
import { Block } from "../Block/Block.jsx";
import { Loader } from "../Loader/Loader.jsx";

import "./PendingStage.css";

import { DepositContext } from "../../context/deposit";
import { StageContext } from "../../context/stage";
import { ETHERSCAN_URLS } from "../../constants/etherscan";
import { ModalContext } from "../../context/modal";
import { MODAL_TYPES } from "../../constants/modal-types";
import { STAKING_STAGES } from "../../constants/stages";
import { mainnetId } from "../../constants/network-chain-ids";

export const PendingStage = () => {
  const { depositInfo } = useContext(DepositContext);
  const { setCurrentModal } = useContext(ModalContext);
  const { setStage } = useContext(StageContext);

  useEffect(() => {
    const waiter = (confirmations = 3) =>
      depositInfo.transaction
        .wait(confirmations)
        .then((receipt) => {
          if (receipt) {
            setStage(STAKING_STAGES.SENT);
          } else {
            waiter(confirmations + 2);
          }
        })
        .catch(() => {
          setCurrentModal(MODAL_TYPES.TRANSACTION_CANCELED);
        });

    waiter();
  }, []);

  return (
    <BaseCard size="md" className="ma">
      <h1 className="h3">Your transaction is pending</h1>
      <Block className="tac pb16 pt16">
        <Loader width={16} height={16} color="var(--black20)" />
      </Block>
      <p className="description pb32" style={{ color: "var(--black50)" }}>
        Do not close the browser screen until the transaction is complete. We
        are waiting for your transaction to be confirmed. You can check the
        progress manually by viewing your transaction on{" "}
        <Link
          href={`${ETHERSCAN_URLS[mainnetId]}/tx/${depositInfo.transaction.hash}`}
          className="description-bold link-w-icon"
        >
          Etherscan <LinkIcon width={14} />
        </Link>
      </p>
      <DetailsList
        data={[
          {
            name: "Deposited funds",
            value: <>{depositInfo?.amount} ETH</>,
          },
          {
            name: "Deposited from",
            value: <>{depositInfo?.transaction.from}</>,
          },
          {
            name: "Withdrawal address",
            value: <>{depositInfo?.withdrawalAddress}</>,
          },
        ]}
      />
    </BaseCard>
  );
};
