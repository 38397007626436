import React, { useContext, useState } from "react";

import { BaseCard } from "../BaseCard/BaseCard";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { Block } from "../Block/Block";

import { ModalContext } from "../../context/modal";

import * as styles from "./LedgerConnectionModal.module.css";
import { useConnect } from "wagmi";

enum ConnectionState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SELECTION = "SELECTION",
  NO_CONNECTION = "NO_CONNECTION",
}

const MODAL_CONTENT: Record<
  ConnectionState,
  { title: string; button: string }
> = {
  [ConnectionState.IDLE]: {
    title: "Connect your Ledger, unlock it and run Ethereum App",
    button: "Continue",
  },
  [ConnectionState.LOADING]: {
    title: "Connect your Ledger, unlock it and run Ethereum App",
    button: "Continue",
  },
  [ConnectionState.SELECTION]: {
    title: "Select your Ledger in the browsers device list pop-up",
    button: "Try again",
  },
  [ConnectionState.NO_CONNECTION]: {
    title: "No connection",
    button: "Try again",
  },
};

export const LedgerConnectionModal = () => {
  const { setCurrentModal } = useContext(ModalContext);
  const { connectAsync, connectors } = useConnect();
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.IDLE
  );

  const handleClose = () => setCurrentModal(null);
  const handleConnect = async () => {
    try {
      await connectAsync({ connector: connectors[1] });
      setCurrentModal(null);
    } catch (e) {
      if (e.name === "TransportOpenUserCancelled") {
        setConnectionState(ConnectionState.SELECTION);
      } else if (e.name === "TransportStatusError") {
        setConnectionState(ConnectionState.IDLE);
      } else {
        setConnectionState(ConnectionState.NO_CONNECTION);
      }
    }
  };

  return (
    <Modal isOpen onClose={handleClose}>
      <BaseCard size="sm" className="tac">
        <Block pb16>
          <Block className={styles.LedgerConnection__instructions}>
            <video muted autoPlay loop>
              <source
                src={require("url:../../../public/ledger-instructions.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Block>
        </Block>

        <h2 className="h4 pb8" id="modal-title">
          {MODAL_CONTENT[connectionState].title}
        </h2>
        <p className={`body-m pb24`}>
          Make sure all other applications using Ledger, including Ledger Live,
          are closed. Enable Blind Signing.
        </p>
        <Button
          fullWidth
          onClick={handleConnect}
          viewState={
            connectionState === ConnectionState.LOADING ? "loading" : undefined
          }
        >
          {MODAL_CONTENT[connectionState].button}
        </Button>
      </BaseCard>
    </Modal>
  );
};
