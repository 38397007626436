import { createContext, Dispatch, SetStateAction } from "react";
import { MODAL_TYPES } from "../constants/modal-types";

type Modal = keyof typeof MODAL_TYPES;

export interface ModalContext {
  currentModal?: Modal;
  setCurrentModal?: Dispatch<SetStateAction<Modal>>;
  createModalHandler?(modal: Modal): {
    handleOpen(): void;
    handleClose(): void;
  };
}

export const ModalContext = createContext<ModalContext>({});
