import invariant from "tiny-invariant";
import { JsonRpcBatchProvider, Network } from "@ethersproject/providers";
import { LedgerSigner } from "./LedgerSigner";
import { checkError, convertToUnsigned } from "./helpers";

import type TransportHID from "@ledgerhq/hw-transport-webusb";
import { LedgerConnectorOptions, TransactionRequestExtended } from "./types";
import { Address, Chain } from "wagmi";

export class LedgerProvider extends JsonRpcBatchProvider {
  public signer?: LedgerSigner;
  public device?: HIDDevice;
  public transport?: typeof TransportHID;

  private _options: LedgerConnectorOptions;

  constructor({
    chain,
    options,
  }: {
    chain: Chain;
    options: LedgerConnectorOptions;
  }) {
    /* TODO: подумать на url, откуда нужно брать эту информацию? Необходимо связаться с @Viktor Kuzenkov для получения
     * необходимой информации
     */
    super(chain.rpcUrls.default.http[0], chain.id);

    this._options = options;
    this.signer = new LedgerSigner(this, options?.derivationPath);
  }

  getSigner(): LedgerSigner {
    if (!this.signer) {
      return new LedgerSigner(this, this._options?.derivationPath);
    }

    return this.signer;
  }

  async detectNetwork(): Promise<Network> {
    return this._network;
  }

  async getTransport(): Promise<TransportHID> {
    invariant(this.transport, "Transport is not defined");

    try {
      const transport = (await this.transport?.create()) as TransportHID;
      this.device = transport.device;

      return transport;
    } catch (error) {
      return checkError(error);
    }
  }

  async activate(): Promise<Address> {
    try {
      const { default: TransportHID } = await import(
        "@ledgerhq/hw-transport-webusb"
      );
      this.transport = TransportHID;

      if (!this.signer) {
        this.signer = this.getSigner();
      }

      return await this.getAddress();
    } catch (error) {
      return checkError(error);
    }
  }

  async deactivate(): Promise<void> {
    this.device = null;
    this.signer = null;
    this.transport = null;
  }

  async getAddress(): Promise<Address> {
    invariant(this.signer, "Signer is not defined");
    return await this.signer.getAddress();
  }

  async request({
    method,
    params,
  }: {
    method: string;
    params?: Array<unknown>;
  }): Promise<unknown> {
    invariant(this.signer, "Signer is not defined");

    if (method === "eth_sendTransaction") {
      const sourceTx = params[0] as TransactionRequestExtended;
      const unsignedTx = await convertToUnsigned(sourceTx);
      const signedTx = await this.signer.signTransaction(unsignedTx);

      return this.send("eth_sendRawTransaction", [signedTx]);
    }

    if (method === "eth_accounts") return [await this.getAddress()];

    return this.send(method, params);
  }
}
