export const formatAddress = (address?: string) => {
  if (!address) {
    return "";
  }

  try {
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  } catch (err) {
    console.error(`Error during formatting address`, err);
  }
};
