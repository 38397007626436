.LedgerConnection__instructions {
  overflow: hidden;
  line-height: 0;
  height: 88px;
  position: relative;
}

.LedgerConnection__instructions video {
  width: 469px;
  height: 116px;
  position: absolute;
  top: 50%;
  left: -42px;
  transform: translateY(-50%);
}
