import React, { useContext } from "react";

import { Block } from "../Block/Block";
import { WalletAvatar } from "../WalletAvatar/WalletAvatar";
import { ModalContext } from "../../context/modal";

import { formatAddress } from "../../helpers/format-eth-address";
import { cn } from "../../helpers/classnames";

import * as styles from "./AccountContent.module.css";

export const AccountContent = ({ balance, account }) => {
  const { setCurrentModal } = useContext(ModalContext);
  const handleOpenAccount = () => setCurrentModal("ACCOUNT");
  return (
    <Block className={styles.AccountContent}>
      <Block>
        <p className={cn(["description", styles.AccountContent__balanceLabel])}>
          Balance:
        </p>
        <span className="body-xl-bold">{balance} ETH</span>
      </Block>
      <Block>
        <button
          aria-label="View account details"
          onClick={handleOpenAccount}
          className={cn(["base-btn", styles.AccountContent__userProfile])}
        >
          <span className={cn(["description", styles.AccountContent__address])}>
            {formatAddress(account)}
          </span>
          <div className={styles.AccountContent__avatar}>
            <WalletAvatar address={account} />
          </div>
        </button>
      </Block>
    </Block>
  );
};
