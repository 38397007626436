import React, { useCallback, useEffect, useState } from "react";

import { DepositStage } from "./components/DepositStage/DepositStage";
import { ExceptionModal } from "./components/ExceptionModal/ExceptionModal";
import { PendingStage } from "./components/PendingStage/PendingStage.jsx";

import { SentToValidatorStage } from "./components/SentToValidatorStage/SentToValidatorStage.jsx";

import { ModalContext } from "./context/modal";
import { DepositContext, DepositInfo } from "./context/deposit";
import { Header } from "./components/Header/Header";
import { MobileNoop } from "./components/MobileNoop/MobileNoop.jsx";
import { StageContext } from "./context/stage";
import { STAKING_STAGES } from "./constants/stages";
import { MODAL_TYPES } from "./constants/modal-types";
import { SelectWalletModal } from "./components/SelectWalletModal/SelectWalletModal";
import { AccountModal } from "./components/AccountModal/AccountModal";
import { LedgerConnectionModal } from "./components/LedgerConnectionModal/LedgerConnectionModal";

import {
  WagmiConfig,
  configureChains,
  goerli,
  mainnet,
  createClient,
} from "wagmi";

// import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { LedgerConnector } from "./web3/ledger/LedgerConnector";

const MOBILE_QUERY = window.matchMedia("(max-width: 768px)");

const stages = {
  [STAKING_STAGES.DEPOSIT]: <DepositStage />,
  [STAKING_STAGES.PENDING]: <PendingStage />,
  [STAKING_STAGES.SENT]: <SentToValidatorStage />,
};

const chainsByStage =
  process.env.NODE_ENV === "production" ? [mainnet] : [goerli];

const { chains, provider, webSocketProvider } = configureChains(chainsByStage, [
  publicProvider(),
]);

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new LedgerConnector({
      chains,
      options: {
        derivationPath: "m/44'/60'/0'/0/0",
      },
    }),
  ],
  provider,
  webSocketProvider,
});

export const App = () => {
  const [stage, setStage] = useState(STAKING_STAGES.DEPOSIT);
  const [currentModal, setCurrentModal] = useState<
    keyof typeof MODAL_TYPES | null
  >(null);
  const [depositInfo, setDepositInfo] = useState<DepositInfo>(null);
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  const handleWidth = ({ matches }) => setIsMobileWidth(matches);

  const createModalHandler = useCallback(
    (modal: keyof typeof MODAL_TYPES | null) => {
      const handleOpen = () => setCurrentModal(modal);
      const handleClose = () => currentModal === modal && setCurrentModal(null);

      return {
        handleClose,
        handleOpen,
      };
    },
    [currentModal]
  );

  useEffect(() => {
    setIsMobileWidth(MOBILE_QUERY.matches);
    MOBILE_QUERY.addEventListener("change", handleWidth);
    return () => MOBILE_QUERY.removeEventListener("change", handleWidth);
  }, []);

  if (isMobileWidth) return <MobileNoop />;

  return (
    <WagmiConfig client={client}>
      <div className="container">
        <StageContext.Provider value={{ setStage }}>
          <DepositContext.Provider value={{ depositInfo, setDepositInfo }}>
            <ModalContext.Provider
              value={{ currentModal, setCurrentModal, createModalHandler }}
            >
              <Header />

              <main>{stages[stage]}</main>

              {currentModal === MODAL_TYPES.CONNECT_LEDGER && (
                <LedgerConnectionModal />
              )}
              {currentModal === MODAL_TYPES.ACCOUNT && <AccountModal />}
              {currentModal === MODAL_TYPES.SELECT_WALLET && (
                <SelectWalletModal />
              )}
              <ExceptionModal />
            </ModalContext.Provider>
          </DepositContext.Provider>
        </StageContext.Provider>
      </div>
    </WagmiConfig>
  );
};
