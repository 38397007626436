import * as React from "react";
const SvgCopy = (props) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9 2.503a.1.1 0 0 1 .1-.1h5.36v2.06c0 1.048.85 1.9 1.9 1.9h1.84v5.14a.1.1 0 0 1-.1.1H5a.1.1 0 0 1-.1-.1v-9Zm8.012 2.06-.752-.797v.696a.1.1 0 0 0 .1.1h.652ZM5 .602a1.9 1.9 0 0 0-1.9 1.9V3.6H2A1.9 1.9 0 0 0 .1 5.5v9c0 1.05.85 1.9 1.9 1.9h9a1.9 1.9 0 0 0 1.9-1.9v-1.098H14a1.9 1.9 0 0 0 1.9-1.9v-6.04a.9.9 0 0 0-.246-.619l-3.74-3.96a.9.9 0 0 0-.654-.281H5Zm6.1 12.8H5a1.9 1.9 0 0 1-1.9-1.9V5.4H2a.1.1 0 0 0-.1.1v9a.1.1 0 0 0 .1.1h9a.1.1 0 0 0 .1-.1v-1.098Z"
      fill="#1347FF"
    />
  </svg>
);
export default SvgCopy;
