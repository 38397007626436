import * as React from "react";
const SvgLink = (props) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#link_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125 8.75v3.5a1.75 1.75 0 0 1-1.75 1.75H1.75A1.75 1.75 0 0 1 0 12.25V2.625C0 1.659.784.875 1.75.875h3.5v1.75h-3.5v9.625h9.625v-3.5h1.75Zm-.877-5.763L6.74 8.494 5.504 7.256 11.01 1.75H7.872V0h6.126v6.125h-1.75V2.987Z"
        fill="#1347FF"
      />
    </g>
    <defs>
      <clipPath id="link_svg__a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLink;
