export class BodyScrollService {
  static get root() {
    return (typeof document !== "undefined" ? document : {}).documentElement;
  }

  static lockScroll() {
    BodyScrollService.root?.classList.add("noscroll");
    BodyScrollService.root?.style.setProperty(
      "--scrollbar-width",
      `${this.getScrollbarWidth()}px`
    );
  }

  static unlockScroll() {
    BodyScrollService.root?.classList.remove("noscroll");
    BodyScrollService.root?.style.setProperty("--scrollbar-width", `0px`);
  }

  static getScrollbarWidth() {
    return typeof window !== "undefined"
      ? window.innerWidth - document.documentElement.clientWidth
      : 0;
  }
}
