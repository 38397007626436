import { useFeeData } from "wagmi";
import { useMemo } from "react";

export const useGasPrice = () => {
  const { data, isRefetching, isLoading } = useFeeData({
    formatUnits: "gwei",
    staleTime: 5_000,
    watch: true,
  });

  return useMemo(
    () => ({
      gasPrice: data?.gasPrice,
      lastBaseFeePerGas: data?.lastBaseFeePerGas,
      maxFeePerGas: data?.maxFeePerGas,
      maxPriorityFeePerGas: data?.maxPriorityFeePerGas,
      formatted: {
        gasPrice: data?.formatted.gasPrice,
        maxFeePerGas: data?.formatted.maxFeePerGas,
        maxPriorityFeePerGas: data?.formatted.maxPriorityFeePerGas,
      },
      isRefetching,
      isLoading,
    }),
    [isRefetching]
  );
};
