.BtnText {
  color: var(--blue);
  padding: 8px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 11px;
}

.BtnText__icon {
  display: grid;
  place-items: center;
  max-width: 18px;
  aspect-ratio: 1/1;
}

.BtnText__icon > * {
  max-width: 100%;
  max-width: 100%;
}
