import * as React from "react";
const SvgLedger = (props) => (
  <svg
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 15.753V22h9.78v-1.385H1.425v-4.862H0Zm24.575 0v4.861H16.22V22h9.781v-6.246h-1.426ZM9.795 6.247v9.506h6.425v-1.25H11.22V6.248H9.794ZM0 0v6.247h1.425V1.385H9.78V0H0Zm16.22 0v1.385h8.355v4.862H26V0h-9.78Z"
      fill="#000"
    />
  </svg>
);
export default SvgLedger;
