import { SMART_CONTRACT_ADDRESS } from "../constants/etherscan";
import ABI from "./SmartContractABI.json";
import { BigNumber, ethers } from "ethers";
import { useAccount } from "wagmi";
import type { DepositDataItem } from "../types/deposit-data-item.interface";
import { useGasPrice } from "./useGasPrice";
import { useCallback } from "react";

export const useStake = () => {
  const { connector, address } = useAccount();
  const { maxPriorityFeePerGas, maxFeePerGas } = useGasPrice();

  const getGasLimit = useCallback(
    async (depositData: DepositDataItem[]) => {
      try {
        const signer = await connector.getSigner();
        const contract = new ethers.Contract(
          SMART_CONTRACT_ADDRESS,
          ABI,
          signer
        );
        const ctxWithSigner = contract.connect(signer);

        const pubkeys = depositData.map((data) => "0x" + data.pubkey);
        const withdrawalCredentials = depositData.map(
          (data) => "0x" + data.withdrawal_credentials
        );
        const signatures = depositData.map((data) => "0x" + data.signature);
        const dataRoots = depositData.map(
          (data) => "0x" + data.deposit_data_root
        );

        return await ctxWithSigner.estimateGas.deposit(
          pubkeys,
          withdrawalCredentials,
          signatures,
          dataRoots,
          {
            from: address,
            value: ethers.utils.parseEther((32 * pubkeys.length).toString()),
            maxFeePerGas,
            maxPriorityFeePerGas,
          }
        );
      } catch {
        return null;
      }
    },
    [connector, maxFeePerGas, maxPriorityFeePerGas, address]
  );

  const stake = useCallback(
    async (depositData: DepositDataItem[]) => {
      if (!depositData) {
        throw new Error("Deposit data must be passed!");
      }

      const signer = await connector.getSigner();
      const contract = new ethers.Contract(SMART_CONTRACT_ADDRESS, ABI, signer);
      const ctxWithSigner = contract.connect(signer);

      const pubkeys = depositData.map((data) => "0x" + data.pubkey);
      const withdrawalCredentials = depositData.map(
        (data) => "0x" + data.withdrawal_credentials
      );
      const signatures = depositData.map((data) => "0x" + data.signature);
      const dataRoots = depositData.map(
        (data) => "0x" + data.deposit_data_root
      );

      const originalGasLimit = await getGasLimit(depositData);

      const gasLimit = originalGasLimit
        ? Math.ceil(originalGasLimit.toNumber() * 1.05)
        : null;

      if (address) {
        return await ctxWithSigner.deposit(
          pubkeys,
          withdrawalCredentials,
          signatures,
          dataRoots,
          {
            from: address,
            value: ethers.utils.parseEther((32 * pubkeys.length).toString()),
            maxFeePerGas,
            maxPriorityFeePerGas,
            gasLimit: gasLimit ? BigNumber.from(gasLimit) : undefined,
          }
        );
      } else {
        throw new Error("You need to connect the connector");
      }
    },
    [connector, address, maxFeePerGas, maxPriorityFeePerGas]
  );

  return { stake, getGasLimit };
};
