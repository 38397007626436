import React from "react";

import { cn } from "../../helpers/classnames";

import "./BaseCard.css";

export const BaseCard = ({ children, size, className }) => (
  <section
    className={cn([
      "base-card",
      {
        "base-card--md-size": size === "md",
        "base-card--sm-size": size === "sm",
      },
      className,
    ])}
  >
    {children}
  </section>
);
