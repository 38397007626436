import * as React from "react";
const SvgEmail = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6.852s7.537 4.28 9 4.28c1.533 0 8.988-4.198 8.988-4.198l.012-.75a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5v.668Zm17.988 2.332s-7.385 4.01-8.988 4.01c-1.463 0-8.989-4.01-8.988-4.01l-.012 9a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5l-.012-9Z"
      fill="#136BFF"
    />
  </svg>
);
export default SvgEmail;
