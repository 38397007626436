import * as React from "react";
const SvgChecked = (props) => (
  <svg
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1 5.667 5.299 6.402a1 1 0 0 0 1.54 0L17 1"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
);
export default SvgChecked;
