import React, { useContext } from "react";
import { useAccount, useNetwork, useDisconnect } from "wagmi";

import { BaseCard } from "../BaseCard/BaseCard";
import { Copy, Link } from "../Icons";
import { Modal } from "../Modal/Modal";
import { Block } from "../Block/Block";
import { Button } from "../Button/Button";
import { TextButton } from "../Button/Text";
import { WalletAvatar } from "../WalletAvatar/WalletAvatar";

import { ModalContext } from "../../context/modal";
import { ETHERSCAN_URLS } from "../../constants/etherscan";
import { cn } from "../../helpers/classnames";
import { formatAddress } from "../../helpers/format-eth-address";

import * as styles from "./AccountModal.module.css";
import { NETWORK_CHAIN_IDS } from "../../constants/network-chain-ids";

export const AccountModal = () => {
  const { setCurrentModal } = useContext(ModalContext);
  const { address, connector: activeConnector } = useAccount();
  const { chain } = useNetwork();
  const { disconnect, isLoading } = useDisconnect({
    onSettled() {
      setCurrentModal(null);
    },
  });

  const handleClose = () => setCurrentModal(null);

  const handleCopyAddress = () => {
    const permissionName = "clipboard-write" as PermissionName;
    navigator.permissions.query({ name: permissionName }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(address);
      } else {
        console.error("copying not allowed");
      }
    });
  };

  return (
    <Modal isOpen onClose={handleClose}>
      <BaseCard className={`tac ${styles.AccountCard}`}>
        <h2 className="h3 pb24" id="modal-title">
          Account
        </h2>
        <Block className="disclaimer-card">
          <Block className={styles.AccountCard__walletRow}>
            <span className={"description"}>
              Connected with {activeConnector?.name}
            </span>
            <Button
              size="sm"
              variant="white"
              viewState={isLoading ? "loading" : undefined}
              onClick={disconnect}
            >
              Disconnect
            </Button>
          </Block>
          <Block className={styles.AccountCard__userRow}>
            <Block className={styles.WalletAvatar}>
              <WalletAvatar address={address} />
            </Block>
            <span className={cn(["body-m"])}>{formatAddress(address)}</span>
          </Block>
          <Block className={styles.AccountCard__actionsRow}>
            <TextButton icon={<Copy width="14" />} onClick={handleCopyAddress}>
              Copy address
            </TextButton>
            <TextButton
              icon={<Link width="14" />}
              href={`${
                ETHERSCAN_URLS[chain?.id || NETWORK_CHAIN_IDS.MAINNET]
              }/address/${address}`}
            >
              View on Etherscan
            </TextButton>
          </Block>
        </Block>
      </BaseCard>
    </Modal>
  );
};
