import { createContext, Dispatch, SetStateAction } from "react";
import type { DepositDataItem } from "../types/deposit-data-item.interface";

export interface DepositInfo {
  amount?: string;
  numberOfValidators?: number;
  withdrawalAddress?: string;
  depositDataId?: string;
  supportChannelLink?: string;
  depositData?: DepositDataItem[];

  transaction?: any;
  from?: string;
}

export interface DepositContext {
  depositInfo?: DepositInfo;
  setDepositInfo?: Dispatch<SetStateAction<DepositInfo>>;
}

export const DepositContext = createContext<DepositContext>({});
