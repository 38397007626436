import React, { useCallback, useContext, useMemo } from "react";

import { EXCEPTION_TYPES, MODAL_TYPES } from "../../constants/modal-types";

import { ModalContext } from "../../context/modal";

import { BaseCard } from "../BaseCard/BaseCard";
import { Block } from "../Block/Block";
import { Button } from "../Button/Button";
import { AlertIcon } from "../Icons";
import { Modal } from "../Modal/Modal";

import { DepositContext } from "../../context/deposit";
import { StageContext } from "../../context/stage";
import { STAKING_STAGES } from "../../constants/stages";

import "./ExceptionModal.css";
import { useAccount, useConnect, useSwitchNetwork } from "wagmi";
import { mainnetId } from "../../constants/network-chain-ids";
import { useStake } from "../../web3/useStake";

interface ExceptionDataItem {
  title: string;
  description(connectorName?: string): string;
  closable?: boolean;
}

const EXCEPTIONS_DATA: Record<EXCEPTION_TYPES, ExceptionDataItem> = {
  [EXCEPTION_TYPES.LOADING_ERROR]: {
    title: "Loading error",
    description: () =>
      "Please reload the page. If this does not help, contact support",
    closable: false,
  },
  [EXCEPTION_TYPES.FUNDS_ALREADY_SENT]: {
    title: "The funds are already on the validators",
    description: () => "Details and updates will be in a dedicated chat",
    closable: false,
  },
  [EXCEPTION_TYPES.UNSUPPORTED_CHAIN]: {
    title: "Unsupported chain",
    description: (connectorName) => `Set Ethereum Mainnet in ${connectorName}`,
  },
  [EXCEPTION_TYPES.ACCESS_REJECTED]: {
    title: "Rejected",
    description: (connectorName: string) =>
      `You have rejected access to ${connectorName}`,
    closable: true,
  },
  [EXCEPTION_TYPES.CONNECTION_ERROR]: {
    title: "Connection error",
    description: () => "Check your network connection",
    closable: false,
  },
  [EXCEPTION_TYPES.INSUFFICIENT_BALANCE]: {
    title: "Insufficient funds",
    description: (connectorName: string) =>
      `Change your account in ${connectorName} to the one that has sufficient funds`,
    closable: true,
  },
  [EXCEPTION_TYPES.TRANSACTION_CANCELED]: {
    title: "Canceled",
    description: (connectorName: string) =>
      `You have not confirmed the transaction in ${connectorName}`,
    closable: true,
  },
};

export const ExceptionModal = () => {
  const { currentModal, setCurrentModal, createModalHandler } =
    useContext(ModalContext);
  const { depositInfo, setDepositInfo } = useContext(DepositContext);
  const { setStage } = useContext(StageContext);
  const { switchNetworkAsync } = useSwitchNetwork();
  const { connector } = useAccount();
  const { connectAsync, connectors } = useConnect();
  const { stake } = useStake();

  const handleSwitchNetwork = useCallback(async () => {
    try {
      await switchNetworkAsync(mainnetId);
      setCurrentModal(null);
    } catch (e) {
      setCurrentModal(EXCEPTION_TYPES.UNSUPPORTED_CHAIN);
    }
  }, [switchNetworkAsync]);

  const handleConnect = useCallback(async () => {
    try {
      const { handleOpen, handleClose } = createModalHandler(
        MODAL_TYPES.PENDING
      );
      handleOpen();
      await connectAsync({ connector: connectors[0] });
      handleClose();
    } catch (e) {
      if (e.code === 4001) {
        setCurrentModal(EXCEPTION_TYPES.ACCESS_REJECTED);
      } else {
        setCurrentModal(EXCEPTION_TYPES.CONNECTION_ERROR);
      }
    }
  }, []);

  const handleSwitchAccount = useCallback(async () => {
    try {
      const provider = await connector?.getProvider();
      await provider.request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      });
      setCurrentModal(null);
    } catch (e) {
      if (e.code === 4001) {
        setCurrentModal(EXCEPTION_TYPES.INSUFFICIENT_BALANCE);
      } else {
        setCurrentModal(EXCEPTION_TYPES.CONNECTION_ERROR);
      }
    }
  }, [connector]);

  const handleStake = useCallback(async () => {
    try {
      setCurrentModal(null);
      const transaction = await stake(depositInfo?.depositData);
      setDepositInfo({ ...depositInfo, transaction });
      setStage(STAKING_STAGES.PENDING);
    } catch (e) {
      if (connector?.id === "ledger") {
        if (e.name === "TransportStatusError") {
          setCurrentModal(EXCEPTION_TYPES.TRANSACTION_CANCELED);
        }
      } else if (connector?.id === "metaMask") {
        if (e.code === 4001 || e.code === "ACTION_REJECTED") {
          setCurrentModal(EXCEPTION_TYPES.TRANSACTION_CANCELED);
        }
      } else {
        setCurrentModal(EXCEPTION_TYPES.CONNECTION_ERROR);
      }
    }
  }, [depositInfo, connector]);

  const modalActions = useMemo(
    () => ({
      [EXCEPTION_TYPES.LOADING_ERROR]: (
        <>
          <Button fullWidth onClick={() => window.location.reload()}>
            Reload page
          </Button>
          <Button
            fullWidth
            variant="black"
            href="https://t.me/P2P_staking"
            target="_blank"
          >
            Contact us
          </Button>
        </>
      ),
      [EXCEPTION_TYPES.FUNDS_ALREADY_SENT]: (
        <Button
          fullWidth
          href={depositInfo?.supportChannelLink}
          target="_blank"
        >
          Go to a dedicated TG Chat
        </Button>
      ),
      [EXCEPTION_TYPES.UNSUPPORTED_CHAIN]: (
        <Button fullWidth onClick={handleSwitchNetwork}>
          Switch network
        </Button>
      ),
      [EXCEPTION_TYPES.ACCESS_REJECTED]: (
        <Button fullWidth onClick={handleConnect}>
          Give permission in Metamask
        </Button>
      ),
      [EXCEPTION_TYPES.CONNECTION_ERROR]: (
        <Button fullWidth onClick={() => window.location.reload()}>
          Reload page
        </Button>
      ),
      [EXCEPTION_TYPES.INSUFFICIENT_BALANCE]:
        connector?.id === "metaMask" ? (
          <Button fullWidth onClick={handleSwitchAccount}>
            Change your account
          </Button>
        ) : (
          <Button fullWidth>OK</Button>
        ),
      [EXCEPTION_TYPES.TRANSACTION_CANCELED]: (
        <Button fullWidth onClick={handleStake}>
          Confirm the transaction
        </Button>
      ),
    }),
    [currentModal, handleSwitchNetwork]
  );

  if (!Object.values(EXCEPTION_TYPES).includes(currentModal as EXCEPTION_TYPES))
    return null;

  const handleCloseModal = EXCEPTIONS_DATA[currentModal]?.closable
    ? () => setCurrentModal(null)
    : undefined;

  return (
    <Modal isOpen={Boolean(currentModal)} onClose={handleCloseModal}>
      <BaseCard size="sm" className="exception-modal">
        <Block className="exception-modal__icon" tac pb16>
          <AlertIcon width="48px" height="48px" />
        </Block>
        <Block tac pb8>
          <h2 className="h3" id="modal-title">
            {EXCEPTIONS_DATA[currentModal]?.title}
          </h2>
        </Block>
        <Block className="exception-modal__description" tac pb24>
          <p className="body-m">
            {EXCEPTIONS_DATA[currentModal]?.description(connector?.name)}
          </p>
        </Block>
        <Block className="exception-modal__actions">
          {modalActions[currentModal]}
        </Block>
      </BaseCard>
    </Modal>
  );
};
