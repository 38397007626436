import * as React from "react";
const SvgSpinnerLoader = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17 9a8 8 0 1 0-8 8" stroke="currentColor" strokeWidth={2} />
  </svg>
);
export default SvgSpinnerLoader;
