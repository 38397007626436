import React, { useRef, useEffect, FC } from "react";

import { getJazziconAvatar } from "../../helpers/get-jazzicon-avatar";

export const WalletAvatar: FC<{ address: string }> = ({ address }) => {
  const rootRef = useRef(null);

  useEffect(() => {
    if (rootRef.current) {
      const avatarNode = getJazziconAvatar(address);
      avatarNode && rootRef.current.appendChild(avatarNode);
    }
  }, []);

  return <div ref={rootRef}></div>;
};
