import * as React from "react";
const SvgP2PLogo = (props) => (
  <svg
    viewBox="0 0 98 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.37 0A.37.37 0 0 0 0 .37v11.112c0 .204.166.37.37.37h28.89a.37.37 0 0 0 .37-.37V.37a.37.37 0 0 0-.37-.37H.37Zm25.37 8.148a.185.185 0 0 0 .186-.185V3.889a.185.185 0 0 0-.185-.185H3.889a.185.185 0 0 0-.185.185v4.074c0 .102.083.185.185.185H25.74Z"
      fill="#0B0B0B"
    />
    <path
      d="M3.704 16.667a.37.37 0 0 0-.37-.37H.37a.37.37 0 0 0-.37.37v2.963c0 .204.166.37.37.37h2.963a.37.37 0 0 0 .37-.37v-2.963ZM71.852 16.667a.37.37 0 0 0-.37-.37h-2.963a.37.37 0 0 0-.37.37v2.963c0 .204.165.37.37.37h2.962a.37.37 0 0 0 .37-.37v-2.963ZM34.074 16.667a.37.37 0 0 1 .37-.37h28.89a.37.37 0 0 1 .37.37v2.963a.37.37 0 0 1-.37.37h-28.89a.37.37 0 0 1-.37-.37v-2.963Z"
      fill="#0B0B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.148.37a.37.37 0 0 1 .37-.37h28.89a.37.37 0 0 1 .37.37v11.112a.37.37 0 0 1-.37.37h-28.89a.37.37 0 0 1-.37-.37V.37Zm25.74 3.334c.103 0 .186.083.186.185v4.074a.185.185 0 0 1-.185.185H72.037a.185.185 0 0 1-.185-.185V3.889c0-.102.083-.185.185-.185h21.852Z"
      fill="#0B0B0B"
    />
    <path
      d="M34.444 0a.37.37 0 0 0-.37.37v2.963c0 .205.166.37.37.37h25.37c.103 0 .186.084.186.186v4.074a.185.185 0 0 1-.185.185h-25.37a.37.37 0 0 0-.37.37v2.963c0 .205.165.37.37.37h28.888a.37.37 0 0 0 .37-.37V.372a.37.37 0 0 0-.37-.371H34.444Z"
      fill="#0B0B0B"
    />
  </svg>
);
export default SvgP2PLogo;
