import React, { useContext, useState } from "react";
import { useConnect } from "wagmi";
import { cn } from "../../helpers/classnames";

import { BaseCard } from "../BaseCard/BaseCard";
import { Ledger, Metamask, Telegram } from "../Icons";
import { Modal } from "../Modal/Modal";
import { Block } from "../Block/Block";

import { ModalContext } from "../../context/modal";

import * as styles from "./SelectWalletModal.module.css";
import { DepositContext } from "../../context/deposit";
import { LedgerConnector } from "../../web3/ledger/LedgerConnector";
import { isMetamaskSupported } from "../../helpers/is-metamask-supported";
import { WALLET_NAMES } from "../../constants/wallet-names";
import { MODAL_TYPES } from "../../constants/modal-types";

const wallets = [
  {
    icon: <Metamask width="32" />,
    name: WALLET_NAMES.MetaMask,
    color: "#FFD3A2",
  },
  {
    icon: <Ledger width="26" />,
    name: WALLET_NAMES.Ledger,
    color: "#FFFFFF",
  },
];

export const SelectWalletModal = () => {
  const { setCurrentModal } = useContext(ModalContext);
  const { depositInfo } = useContext(DepositContext);
  const { connectAsync, connectors } = useConnect();
  const [unsupportedWallet, setUnsupportedWallet] = useState("");

  const handleSelectWallet = {
    [WALLET_NAMES.Ledger]: async () => {
      try {
        const connector = connectors[1] as LedgerConnector;
        const isSupported = connector?.isSupported();

        if (isSupported) {
          setCurrentModal(MODAL_TYPES.CONNECT_LEDGER);
        } else {
          setUnsupportedWallet(WALLET_NAMES.Ledger);
        }
      } catch (e) {
        console.error(e.code, e.message);

        if (e.code === 4001) {
          setCurrentModal(MODAL_TYPES.ACCESS_REJECTED);
        } else {
          setCurrentModal(MODAL_TYPES.CONNECTION_ERROR);
        }
      }
    },
    [WALLET_NAMES.MetaMask]: async () => {
      try {
        if (!isMetamaskSupported()) {
          setUnsupportedWallet(WALLET_NAMES.MetaMask);
          return;
        }

        setCurrentModal(MODAL_TYPES.PENDING);

        const connector = connectors[0];
        await connectAsync({ connector });

        setCurrentModal(null);
      } catch (e) {
        if (e.code === 4001) {
          setCurrentModal(MODAL_TYPES.ACCESS_REJECTED);
        } else {
          setCurrentModal(MODAL_TYPES.CONNECTION_ERROR);
        }
      }
    },
  };

  const handleClose = () => setCurrentModal(null);

  return (
    <Modal isOpen onClose={handleClose}>
      <BaseCard className={`tac ${styles.WalletsCard}`}>
        <h2 className="h3 pb8" id="modal-title">
          Connect wallet
        </h2>
        <p className={`body-m pb24 ${styles.WalletsCard__description}`}>
          To start using staking
        </p>

        <Block className={styles.WalletList} pb16>
          {wallets.map((wallet) => (
            <button
              className={cn([
                "base-btn",
                styles.WalletBtn,
                {
                  [styles["WalletBtn--error"]]:
                    wallet.name === unsupportedWallet,
                },
              ])}
              key={wallet.name}
              onClick={handleSelectWallet[wallet.name]}
            >
              <div
                className={styles.WalletBtn__icon}
                style={{ color: wallet.color }}
              >
                {wallet.icon}
              </div>
              <span className="wallet-btn__name description-bold">
                {wallet.name}
              </span>
            </button>
          ))}
        </Block>

        {unsupportedWallet === WALLET_NAMES.Ledger && (
          <Block pb16>
            <p className={`description ${styles.WalletsCard__message}`}>
              Not compatible with your browser. Use{" "}
              <a
                href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop#zippy="
                target="_blank"
                className={styles.WalletsCard__message}
              >
                Chrome
              </a>{" "}
              or{" "}
              <a
                href="https://www.opera.com/ru/download"
                target="_blank"
                className={styles.WalletsCard__message}
              >
                Opera
              </a>{" "}
              browser.
            </p>
          </Block>
        )}

        {unsupportedWallet === WALLET_NAMES.MetaMask && (
          <Block pb16>
            <p className={`description ${styles.WalletsCard__message}`}>
              MetaMask not found. Click to{" "}
              <a
                href="https://metamask.io/download/"
                target="_blank"
                className={styles.WalletsCard__message}
              >
                install
              </a>{" "}
              MetaMask
            </p>
          </Block>
        )}

        <Block className={`disclaimer-card ${styles.WalletsCard__contact}`}>
          <Telegram width="24" />
          <p className="body-s">
            If you don't have it,{" "}
            <a
              href={depositInfo?.supportChannelLink || "#"}
              target="_blank"
              className="app-link"
            >
              get help in the support chat
            </a>
          </p>
        </Block>
      </BaseCard>
    </Modal>
  );
};
