import React, { useContext, useEffect, useState } from "react";

import { Button } from "../Button/Button.jsx";
import { BaseCard } from "../BaseCard/BaseCard";
import { DetailsList } from "../DetailsList/DetailsList.jsx";
import { Link } from "../Link/Link.jsx";
import { Block } from "../Block/Block.jsx";
import { AccountContent } from "../AccountContent/AccountContent.jsx";
import { Link as LinkIcon } from "../Icons";

import { ModalContext } from "../../context/modal";
import { MODAL_TYPES } from "../../constants/modal-types";
import { PendingModal } from "../PendingModal/PendingModal";
import { DepositContext } from "../../context/deposit";
import { StageContext } from "../../context/stage";

import {
  ETHERSCAN_URLS,
  SMART_CONTRACT_ADDRESS,
} from "../../constants/etherscan";
import { STAKING_STAGES } from "../../constants/stages";
import { HOST, S3_BASE_URL } from "../../constants/host";

import { fetcher } from "../../helpers/fetcher.js";
import { isUUID } from "../../helpers/is-uuid.js";

import "./DepositStage.css";

import { useAccount, useBalance, useNetwork } from "wagmi";
import { mainnetId } from "../../constants/network-chain-ids";
import { useStake } from "../../web3/useStake";

export const DepositStage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInsufficientBalance, setInsufficientBalance] = useState(true);

  const { currentModal, setCurrentModal, createModalHandler } =
    useContext(ModalContext);
  const { depositInfo, setDepositInfo } = useContext(DepositContext);
  const { setStage } = useContext(StageContext);

  const { stake, getGasLimit } = useStake();

  const { address, connector } = useAccount();

  const { data: balance } = useBalance({ address });
  const { chain } = useNetwork();

  const setupDepositStage = async (id) => {
    const [error, data] = await fetcher(
      `${HOST}/api/v1/staking/manual/${id}/info`
    );

    if (error) {
      return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    }

    if (data.status >= 400) {
      return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    }

    if (!isUUID(data?.depositDataId)) {
      return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    }

    const [depositError, responseDepositData] = await fetcher(
      `${S3_BASE_URL}/${data.depositDataId}.json`
    );

    if (depositError) {
      console.error("DepositError: ", depositError);
      return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    }

    const pubkeys = responseDepositData.map(({ pubkey }) => pubkey);
    const amount = responseDepositData.reduce((totalAmount, depositData) => {
      return totalAmount + depositData.amount / 10 ** 9;
    }, 0);
    const withdrawalAddress = responseDepositData[0]["withdrawal_credentials"];

    setDepositInfo({
      amount: amount,
      numberOfValidators: pubkeys.length,
      withdrawalAddress: withdrawalAddress,
      depositDataId: data.depositDataId,
      supportChannelLink: data.supportChannelLink,
      depositData: responseDepositData,
    });

    const [checkDepositError, checkDepositResponse] = await fetcher(
      `${HOST}/api/v1/staking/check-deposit-sent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          pubkeys,
        },
      }
    );

    if (checkDepositError) {
      return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    }

    if (checkDepositResponse.status) {
      return setCurrentModal(MODAL_TYPES.FUNDS_ALREADY_SENT);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    if (!id) return setCurrentModal(MODAL_TYPES.LOADING_ERROR);
    setupDepositStage(id);
  }, []);

  useEffect(() => {
    getGasLimit(depositInfo?.depositData).then((limit) =>
      setInsufficientBalance(!limit)
    );
  }, [depositInfo?.depositData, address, balance, chain]);

  useEffect(() => {
    if (!address) {
      return;
    }

    const { handleOpen, handleClose } = createModalHandler(
      MODAL_TYPES.UNSUPPORTED_CHAIN
    );

    if (chain?.id !== mainnetId) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [address, chain, createModalHandler]);

  useEffect(() => {
    const isAccountConnected = window.localStorage.getItem("wagmi.connected");
    const isLedger = window.localStorage.getItem("wagmi.wallet");

    if (!address && isAccountConnected && isLedger) {
      setCurrentModal(MODAL_TYPES.CONNECT_LEDGER);
    }
  }, [connector]);

  const handleConnect = async () => {
    setCurrentModal(MODAL_TYPES.SELECT_WALLET);
  };

  const handleStake = async () => {
    try {
      setCurrentModal(MODAL_TYPES.PENDING);
      const transaction = await stake(depositInfo?.depositData);
      setDepositInfo((prev) => ({ ...prev, transaction, from: address }));
      setStage(STAKING_STAGES.PENDING);
    } catch (e) {
      console.error(e);

      if (e.error?.code === -32000) {
        setCurrentModal(MODAL_TYPES.INSUFFICIENT_BALANCE);
      } else {
        setCurrentModal(MODAL_TYPES.TRANSACTION_CANCELED);
      }
    }
  };

  return (
    <>
      <BaseCard size="md" className="ma">
        <h1 className="h3 pt8 pb16">Sending deposits to validators</h1>
        <p className="body-s disclaimer-card mb32">
          Please <span className="body-s-bold">double check your deposit</span>{" "}
          details.{" "}
          <span className="body-s-bold">If you don't have Metamask</span> or you
          encountered another issue, please contact our customer support
        </p>

        {address && balance && (
          <Block pb32>
            <AccountContent
              account={address}
              balance={Number(balance?.formatted).toFixed(4)}
            />
          </Block>
        )}

        <DetailsList
          className="pb24"
          isLoading={isLoading}
          skeletonData={[
            { width: "26.3%" },
            { width: "17%" },
            { multilineConfig: ["100%", "26.3%"] },
            { width: "33.1%" },
          ]}
          data={[
            {
              name: "Amount to stake",
              value: `${depositInfo?.amount} ETH`,
            },
            {
              name: "Number of validators",
              value: <span>{depositInfo?.numberOfValidators}</span>,
            },
            {
              name: "Withdrawal address",
              value: <span>{depositInfo?.withdrawalAddress}</span>,
            },
            {
              name: "Deposit data file",
              value: (
                <Link
                  href={`${S3_BASE_URL}/${depositInfo?.depositDataId}.json`}
                  className="link-w-icon"
                  download="deposit.json"
                >
                  Open
                </Link>
              ),
              tooltipContent: (
                <>
                  Data for transaction (contains your validator's addresses and
                  withdrawal credentials)
                </>
              ),
            },
          ]}
        />
        <p className="body-s pb24">
          You send deposits via the P2P smart contract,{" "}
          <Link
            href={`${HOST}/networks/ethereum/staking/audit.pdf`}
            className="app-link"
          >
            audited by Mixbytes
          </Link>{" "}
          <span className="body-s-bold">{SMART_CONTRACT_ADDRESS}</span> <br />
          <Link
            href={`${ETHERSCAN_URLS[mainnetId]}/address/${SMART_CONTRACT_ADDRESS}`}
            className="app-link"
          >
            View on Etherscan <LinkIcon width={14} />
          </Link>
        </p>
        {!address ? (
          <Button
            onClick={handleConnect}
            fullWidth
            viewState={isLoading ? "loading" : undefined}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            onClick={handleStake}
            fullWidth
            viewState={isLoading ? "loading" : undefined}
            disabled={isInsufficientBalance}
          >
            {isInsufficientBalance
              ? "Insufficient balance"
              : `Stake ${depositInfo?.amount} ETH`}
          </Button>
        )}
      </BaseCard>
      {currentModal === MODAL_TYPES.PENDING && <PendingModal />}
    </>
  );
};
