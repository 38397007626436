import * as React from "react";
const SvgBicycle = (props) => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4 20c.8 0 1.4-.4 1.8-1.1l2.9-5.9h.8l.6 1.7C18 15.6 17 17.2 17 19c0 2.8 2.2 5 5 5s5-2.2 5-5-2.2-5-5-5c-.2 0-.4 0-.6.1l-1-3.1H22c.4 0 .8.3.9.7.1.2.3.3.5.3h1c.2 0 .3-.1.4-.2.1-.1.1-.3.1-.4C24.7 10 23.4 9 22 9h-2.7c-.4 0-.8.2-1 .5-.2.3-.3.7-.2 1.1l.2.4h-7c-.7 0-1.3.6-1.3 1.2v.3c0 .2 0 .3-.1.5l-.6 1.2c-.4-.1-.9-.2-1.3-.2-2.8 0-5 2.2-5 5s2.2 5 5 5c2 0 3.8-1.2 4.6-3h-2.4c-.5.6-1.3 1-2.2 1-1.7 0-3-1.3-3-3s1.3-3 3-3h.4l-.9 1.8c-.2.5-.2 1 .1 1.5.3.5.7.7 1.2.7h4.6Zm-3.8-2 .5-1.1c.3.3.5.7.7 1.1H9.6Zm3.8 0h-.5c-.2-1.2-.9-2.2-1.8-2.9l.6-1.2.3-.9h3.9l-2.5 5Zm8.6 4c-1.7 0-3-1.3-3-3 0-1 .5-1.8 1.2-2.4l.7 2.2c.1.3.4.5.7.5h1.1c.2 0 .4-.2.3-.5l-1-2.9c1.6 0 2.9 1.4 2.9 3C25 20.7 23.7 22 22 22ZM8.5 9h3c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5Z"
      fill="#000"
    />
  </svg>
);
export default SvgBicycle;
