import React from "react";
import { useAccount } from "wagmi";

import { BaseCard } from "../BaseCard/BaseCard";
import { Modal } from "../Modal/Modal";
import { Loader } from "../Loader/Loader";
import { Block } from "../Block/Block";

export const PendingModal = () => {
  const { connector } = useAccount();

  return (
    <Modal isOpen>
      <BaseCard size="sm" className="tac">
        <Block pb24>
          <Loader />
        </Block>
        <h2 className="body-xl-bold" id="modal-title">
          Please confirm the action in {connector?.name || "MetaMask"}
        </h2>
      </BaseCard>
    </Modal>
  );
};
