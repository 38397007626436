import React, { createElement, forwardRef, useCallback } from "react";

import { ArrowRight, Checked, SpinnerLoader } from "../Icons";
import { cn } from "../../helpers/classnames";

import "./Button.css";

export const Button = forwardRef((props, ref) => {
  const {
    children,
    fullWidth = false,
    variant = "primary",
    disabled,
    size = "md",
    viewState,
    href,
    scroll,
    onClick,
    className,
    icon,
    errorText = "Error",
    ...restProps
  } = props;

  const handleClick = useCallback(
    (e) => {
      const linkElement = e.currentTarget.querySelector("a");
      linkElement?.click();
      onClick?.(e);
    },
    [onClick]
  );

  const ButtonRootElement = (props) =>
    createElement(href ? "a" : "button", props);

  const disabledState = disabled || Boolean(viewState);

  return (
    <ButtonRootElement
      {...restProps}
      onClick={handleClick}
      href={disabledState ? undefined : href}
      disabled={disabledState}
      /**
       * if ButtonRootElement is link, we can't use "disabled" attribute,
       * so we need override restriction with "data-disabled"
       */
      data-disabled={disabledState}
      /**/
      ref={ref}
      className={cn([
        "base-btn",
        "default-btn",
        {
          "default-btn--color-primary": variant === "primary",
          "default-btn--color-white": variant === "white",
          "default-btn--color-black": variant === "black",
        },
        {
          "default-btn--loading": viewState === "loading",
          "default-btn--success": viewState === "success",
          "default-btn--error": viewState === "error",
          "default-btn--disabled": disabled,
        },
        {
          "default-btn--size-sm": size === "sm",
          "default-btn--size-md": size === "md",
          "default-btn--size-full-width": fullWidth,
        },
        className,
      ])}
    >
      {!viewState && (
        <span className={cn(["default-btn__children"])}>
          {icon && <span className="default-btn__icon-wrapper">{icon}</span>}
          <span className={size === "md" ? "md" : "sm"}>{children}</span>
        </span>
      )}
      <span
        aria-hidden="true"
        className={cn([
          "default-btn__content",
          {
            "default-btn__content--visible": Boolean(viewState),
            "default-btn__content--black":
              viewState && variant === "white" && viewState === "loading",
            "default-btn__content--white":
              viewState && variant === "white" && viewState !== "loading",
          },
        ])}
      >
        {!viewState && size === "md" && <ArrowRight className="arrow" />}
        {viewState === "loading" && <SpinnerLoader className="spinner" />}
        {viewState === "success" && <Checked className="checked" />}
        {viewState === "error" && errorText}
      </span>
    </ButtonRootElement>
  );
});

Button.displayName = "DefaultButton";
