export enum EXCEPTION_TYPES {
  LOADING_ERROR = "LOADING_ERROR",
  FUNDS_ALREADY_SENT = "FUNDS_ALREADY_SENT",
  UNSUPPORTED_CHAIN = "UNSUPPORTED_CHAIN",
  ACCESS_REJECTED = "ACCESS_REJECTED",
  CONNECTION_ERROR = "CONNECTION_ERROR",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  TRANSACTION_CANCELED = "TRANSACTION_CANCELED",
}

export enum OTHER_TYPES {
  PENDING = "PENDING",
  ACCOUNT = "ACCOUNT",
  SELECT_WALLET = "SELECT_WALLET",
  CONNECT_LEDGER = "CONNECT_LEDGER",
}

export const MODAL_TYPES = { ...EXCEPTION_TYPES, ...OTHER_TYPES };
