import React, { createElement } from "react";
import { cn } from "../../helpers/classnames";

export const Block = ({
  as = "div",
  children,
  className,
  pt8,
  pb8,
  pt16,
  pb16,
  pt24,
  pb24,
  pt32,
  pb32,
  mt8,
  mb8,
  mt16,
  mb16,
  mt24,
  mb24,
  mt32,
  mb32,
  tac,
  ma,
  ...restprops
}) => {
  let BlockRoot;

  try {
    BlockRoot = (props) => createElement(as, props);
  } catch (error) {
    console.log(`Error while BlockRoot rendering: ${error}`);
    BlockRoot = (props) => createElement("div", props);
  }

  return (
    <BlockRoot
      {...restprops}
      className={cn([
        "markup-block",
        {
          pt8,
          pb8,
          pt16,
          pb16,
          pt24,
          pb24,
          pt32,
          pb32,
          mt8,
          mb8,
          mt16,
          mb16,
          mt24,
          mb24,
          mt32,
          mb32,
          tac,
          ma,
        },
        className,
      ])}
    >
      {children}
    </BlockRoot>
  );
};
