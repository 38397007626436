import * as React from "react";
const SvgModalCloseIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#modal-close-icon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m13.414 12.142 6.364-6.364-1.414-1.414L12 10.728 5.636 4.364 4.222 5.778l6.364 6.364-6.364 6.364 1.414 1.414L12 13.556l6.364 6.364 1.414-1.414-6.364-6.364Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="modal-close-icon_svg__a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgModalCloseIcon;
