.WalletsCard {
  width: 488px;
}

.WalletsCard__description {
  color: var(--black50);
}

.WalletsCard__message {
  color: var(--red);
}

:global(.disclaimer-card).WalletsCard__contact {
  display: flex;
  gap: 11px;
  padding: 24px;
}

.WalletList {
  display: flex;
  gap: 16px;
  justify-content: stretch;
}

.WalletBtn {
  display: grid;
  justify-items: center;
  background-color: var(--ultraLightGrey);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  row-gap: 10px;
}

.WalletBtn--error {
  outline: 1px solid var(--red);
}

.WalletBtn:not(:disabled):active {
  background-color: var(--lightGrey);
}

.WalletBtn:not(:disabled):global(.focus-visible) {
  outline-width: 8px;
  outline-offset: 0;
  outline-style: solid;
  outline-color: var(--blue20);
}

.WalletBtn__icon {
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  padding: 10px;
  background-color: currentColor;
}

.WalletBtn__icon svg {
  max-width: 100%;
  max-height: 100%;
}
